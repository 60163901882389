<template>
  <div>
    <template v-if="programId !== 'new'">
	    <h3>
		    <live-edit
		    :value="program.programLabel"
        :inline="false"
        :editMode="editMode"
		    @input="putProgram('programLabel', $event)"
		    @is-editable="setIsEditable"
        placeholder="Program Label"
		    /><small v-if="!isEditable" 
		    class="float-right pt-1">&nbsp;(<a href="#" 
		    @click="deleteProgram()" 
		    :title="'Delete ' + program.programLabel">&nbsp;X&nbsp;</a>)</small>
	    </h3>
	    <div>
		    <live-edit
		    :value="program.programDescription"
        :multiline="true"
        :editMode="editMode"
		    @input="putProgram('programDescription', $event)"
		    placeholder="Program Description"
		    ></live-edit>
	    </div>
    </template>
    
    <template v-else>
      <h3><live-edit
      :value="program.programLabel"
      editMode="true"
      @input="postProgram('programLabel', $event)"
      placeholder="Program Label"
      :inline="false"
      @cancel="$emit('new-program-cancel')"
      ></live-edit></h3>
    </template>
  </div>
</template>

<script>
import LiveEdit from '@/components/LiveEdit'

export default {
  name: 'SportProgram',
  components: {
    LiveEdit
  },
  props: ['programId', 'sportId', 'editMode'],
  data () {
    return {
      isEditable: false,
      program: {
        programLabel: '',
        programDescription: ''
      }
    }
  },
  methods: {
    setIsEditable (val) {
      this.isEditable = val
    },
    loadProgram () {
      if (this.programId === 'new') {
        this.program = {
          programLabel: '',
          programDescription: ''
        }
        return
      }
      let parts = this.programId.split('/')
      if (typeof parts[parts.length - 1]  !== 'undefined') {
        this.$store.dispatch('getSportProgram', {id: parts[parts.length - 1]}).then(() => {
          this.program = this.$store.getters.sportProgram
        })
      }
    },
    postProgram (key, event) {
      let save = {} 
      save.sport = '/api/sports/' + this.sportId // @XXX
      save.programLabel = event
      save.slug = save.programLabel.replace(/\s/g, '-').replace(/[^0-9a-z\-]/ig, '').toLowerCase()
      save.programDescription = ''
      this.$store.dispatch('postSportProgram', save).then(resp => {
        this.$emit('program-id', resp.data['@id'])
      })
    },
    putProgram (key, event) {
      let save = {} 
      save[key] = event
      let pid = this.programId.split('/')
      let obj = { 
          id: pid[pid.length - 1], 
          data: save
      }
      this.$store.dispatch('putSportProgram', obj).then(() => {
        this.program = this.$store.getters.sportProgram
      })
    },
    deleteProgram () {
      if (!confirm('Delete program "' + this.program.programLabel + '?"')) {
        return
      }
      let programId = this.programId.split('/')
      this.$store.dispatch('deleteSportProgram', programId[programId.length - 1]).then(() => {
        this.$emit('program-deleted', this.programId)
      })
    }
  },
  watch: {
    programId () {
      this.program = {
        programLabel: '',
        programDescription: ''
      }
      this.loadProgram()
    }
  },
  created () {
    this.loadProgram()
  },
  destroyed () {
    this.$store.dispatch('resetSportProgram')
  }
}
</script>