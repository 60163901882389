<template>
	<span>
		<b-modal :scrollable="false" size="lg" v-model="editable" :title="modalTitle" :hide-footer="true">

			<div v-if="multiline">
				<textarea v-model="modelvalue" :placeholder="placeholder" @keyup.stop="changed = true"
					ref="editMultiline" class="live-edit form-control" rows="9"></textarea>
				<div class="clearfix">
					<b-button-group class="float-right">
						<b-button variant="success" @click="updateValue()">Save</b-button>
						<b-button variant="danger" @click="cancelChange()">Cancel</b-button>
					</b-button-group>
				</div>
			</div>

			<b-input-group v-else-if="options">
				<b-form-select ref="editLine" v-model="modelvalue" :options="options" @change="changed = true">
				</b-form-select>
				<b-input-group-append>
					<b-btn variant="success" @click="updateValue()">Save</b-btn>
					<b-btn variant="danger" @click="cancelChange()">Cancel</b-btn>
				</b-input-group-append>
			</b-input-group>

			<b-input-group v-else>
				<input v-model="modelvalue" :type="inputType" :placeholder="placeholder" :maxlength="maxlength"
					@keyup.stop="changed = true" @keyup.enter.stop="updateValue()" ref="editLine"
					class="live-edit form-control">
				<b-input-group-append>
					<b-btn variant="success" @click="updateValue()">Save</b-btn>
					<b-btn variant="danger" @click="cancelChange()">Cancel</b-btn>
				</b-input-group-append>
			</b-input-group>
		</b-modal>
		<template>
			<!--  v-if="!editable" -->
			<a v-if="editMode" :style="{ color: fontColor }" @click.prevent="makeEditable()" href="#"
				v-html="displayValue"></a>
			<span v-else :style="{ color: fontColor }" v-html="displayValue"></span>
		</template>
	</span>
</template>

<script>
export default {
	name: 'LiveEdit',
	props: {
		inputType: {
			type: String,
			default: 'text',
		},
		value: {
			// type: [String, Number, Object], // @TODO allow null through custom validation
			required: true,
			default() {
				return ''
			}
		},
		options: {
			type: Array,
			default() {
				return null
			}
		},
		inline: {
			type: Boolean,
			default: true,
		},
		multiline: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String
		},
		textColor: {
			type: String,
			default: '#444',
		},
		emptyColor: {
			type: String,
			default: '#FF0000',
		},
		maxlength: {
			type: Number,
			default: 255,
		},
		editMode: {
			default: true,
		},
		modalTitle: {
			type: String,
			default() { return '' }
		}
	},
	data() {
		return {
			editable: false,
			changed: false,
			modelvalue: null
		}
	},
	computed: {
		displayValue() {
			let modelvalue = this.modelvalue
			if (this.options) {
				for (let i in this.options) {
					if (this.options[i].value === this.modelvalue) {
						modelvalue = this.options[i].text.replace(/\n/g, '<br />')
					}
				}
			}
			return modelvalue || this.placeholder
		},
		fontColor() {
			return this.value ? this.textColor : this.emptyColor
		},
		width() {
			return this.inline ? 'auto' : '100%'
		}
	},
	methods: {
		onKeyUp(e) {
			this.changed = true
			if (e.keyCode === 13) {
				this.updateValue()
			}
		},
		makeEditable() {
			if (!this.editMode) {
				return;
			}
			this.editable = true
			if (this.multiline) {
				this.$nextTick(() => this.$refs.editMultiline.focus())
			} else {
				this.$nextTick(() => this.$refs.editLine.focus())
			}
		},
		updateValue() {
			if (!this.editable) {
				return
			}
			if (this.changed) {
				if (this.multiline) {
					this.$emit('input', this.$refs.editMultiline.value)
				} else {
					this.$emit('input', this.$refs.editLine.value)
				}
				this.changed = false
			}
			this.editable = false
		},
		cancelChange() {
			this.changed = false
			this.editable = false
			this.$emit('cancel')
		}
	},
	mounted() {
		this.modelvalue = this.value
	},
	watch: {
    value () {
      this.modelvalue = this.value
    },
		editable(val) {
			this.$emit('is-editable', val)
		}
	}
}
</script>

<style scoped>
.live-edit {
	font-size: 1em !important;
	display: inline-block !important;
}
</style>
