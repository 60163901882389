<template>
  <div>
    <b-form-select v-model="sportSelectedKey" :options="sportOptions" class="mb-3" size="lg" />
    <b-container fluid v-if="currentSportData.id">
	    <b-row>
	      <b-col sm="2" md="3" lg="2">
	        <b-img v-if="currentSportData.headerImage" :src="'http://v2.nzonesports.com/'+currentSportData.headerImage" alt="" thumbnail fluid /><br />
	        <live-edit
            v-model="currentSportData.headerImage"
            :placeholder="liveEditPlaceHolder"
            :editMode="editMode"
            :inline="false"
            ></live-edit><hr />
            <b-btn @click="editMode = !editMode" :variant="editVariant">Edit Mode</b-btn>
	      </b-col>
	      <b-col>
          <h2>
            <live-edit
              :value="currentSportData.label"
              :editMode="editMode"
              @input="updateSport('label', $event)"
              :placeholder="liveEditPlaceHolder"
              :inline="false"
            ></live-edit>
          </h2>
          <p>http://nzonesports.com/leagues/<live-edit
            :value="currentSportData.slug"
            :editMode="editMode"
            @input="updateSport('slug', $event)"
            :placeholder="liveEditPlaceHolder"
            ></live-edit></p>
          <p><b>SEO Description (shown in search engine results):</b><br />
          <live-edit
            :value="currentSportData.metaDescription"
            :editMode="editMode"
            @input="updateSport('metaDescription', $event)"
            :placeholder="liveEditPlaceHolder"
            :inline="false"
            ></live-edit></p>
		      <p><b>Summary:</b><br />
		      <live-edit
            :value="currentSportData.summary"
            :editMode="editMode"
            @input="updateSport('summary', $event)"
            :placeholder="liveEditPlaceHolder"
            :inline="false"
            :multiline="true"
            ></live-edit></p>
	      </b-col>
	    </b-row>
	    <b-row>
        <b-col sm="2" md="3" lg="2"></b-col>
        <b-col>

	        <draggable v-if="currentSportData.programs" 
            v-model="currentSportData.programs" 
            :options="sortOptions"
            >
	          <div v-for="program in currentSportData.programs" class="mb-3">

	            <hr />

	            <SportProgram v-if="program !== 'new'" 
	              :sportId="currentSportData.id" 
	              :programId="program" 
	              :editMode="editMode"
	              @program-id="newProgramSet" 
	              @program-deleted="programRmv" 
	              @new-program-cancel="newProgramCancel()" />

	          </div>
	        </draggable>

          <SportProgram v-if="newProgramKey !== null" 
            :sportId="currentSportData.id" 
            programId="new" 
            @program-id="newProgramSet" 
            @program-deleted="programRmv" 
            @new-program-cancel="newProgramCancel()" />

          <b-button v-if="!addProgram" @click="newProgramIni()" variant="success" class="mb-3">Add a Program</b-button>
          <b-button v-else @click="newProgramCancel()" variant="danger" class="mb-3">Cancel</b-button>

		      <div v-if="currentSportData.id==='new'">
		        <b-button-group>
		          <b-button @click="saveSport()" variant="success">Save This Sport Data</b-button>
		          <b-button @click="sportSelectedKey = null" variant="danger">Cancel</b-button>
		        </b-button-group>
		      </div>
        </b-col>
	    </b-row>
    </b-container>
  </div>
</template>

<script>
import LiveEdit from '@/components/LiveEdit'
import SportForm from './SportForm'
import SportProgram from './SportProgram'
import draggable from 'vuedraggable'

export default {
  name: "sports-offered",
  components: {
    LiveEdit,
    SportForm,
    SportProgram,
    draggable
  },
  data () {
    return {
      liveEditPlaceHolder: 'Empty',
      sportOptions: [
        { value: null, text: 'Please select a sport to view / edit' },
        { value: 'void', text: '------------', disabled: true }
      ],
      sportSelectedKey: null,
      sportsData: [],
      currentSportData: {},
      newProgramKey: null,
      editMode: false,
      sortOptions: {
        disabled: false
      }
    }
  },
  computed: {
    metaDescription () {
      return this.currentSportData.metaDescription ? this.currentSportData.metaDescription : ''
    },
    addProgram () {
      return (this.newProgramKey !== null)
    },
    editVariant () {
      return this.editMode ? 'success' : 'secondary'
    }
  },
  filters: {
    getProgamId (value) {
      if (!value) return ''
      let parts = value.split('/')
      return parts[parts.length - 1]
    }
  },
  methods: {
    newProgramIni () {
      this.currentSportData.programs.push('new')
      this.newProgramKey = this.currentSportData.programs.length ? (this.currentSportData.programs.length - 1) : 0
    },
    newProgramSet (programId) {
      this.currentSportData.programs[this.newProgramKey] = programId
      this.newProgramKey = null
    },
    newProgramCancel () {
      if (this.newProgramKey !== null) {
        this.currentSportData.programs.splice(this.newProgramKey, 1)
        this.newProgramKey = null
      }
    },
    programRmv (id) {
      let p = this.currentSportData.programs
      for (let k in p) {
        if (p[k] == id) {
          this.currentSportData.programs.splice(k, 1)
        }
      }
    },
    updateSport (key, val) {
      this.currentSportData[key] = val
      if (this.currentSportData.id !== 'new') {
        let save = {} 
        save[key] = val
        let obj = { 
            id: this.currentSportData.id, 
            data: save
        }
        this.$store.dispatch('putSport', obj).then(res => {
          this.sportsData[this.sportSelectedKey] = res
          // this.sportOptions[this.sportSelectedKey].text = res.label
          this.setOptions()
        })
      }
    },
    saveSport () {
      if (this.currentSportData.id === 'new') {
        delete this.currentSportData.id
      }
      this.$store.dispatch('postSport', this.currentSportData).then(res => {
        let nextSportsKey = (this.sportsData.length)
        this.sportsData[nextSportsKey] = res
        if (this.setOptions()) {
          this.sportSelectedKey = nextSportsKey
        }
      })
    },
    setSlug () {
      let slug = this.currentSportData.label.toLowerCase()
      this.currentSportData.slug = slug.trim().replace(/\s/g, '-')
    },
    setOptions () {
      let sportOptions = [
        { value: null, text: 'Please select a sport to view / edit' },
        { value: 'void', text: '------------', disabled: true }
      ]
      for (let i in this.sportsData) {
        let opt = { 
          value: i, 
          text: this.sportsData[i].label
        }
        sportOptions.push(opt)
      }
      sportOptions.push({ value: 'void', text: '------------', disabled: true })
      sportOptions.push({ value: 'new', text: 'Add a New Sport' })
      this.sportOptions = sportOptions
      return true
    }
  },
  watch: {
    editMode (val) {
      if (val) {
        this.sortOptions.disabled = true
      } else {
        this.sortOptions.disabled = false
      }
    },
    sportSelectedKey (val) {
      this.currentSportData = {}
      if (val && val !== 'void' && val !== 'new') {
        this.currentSportData = this.sportsData[val]
      } else if (val === 'new') {
        this.currentSportData = {
            "id":'new',
            "label":"",
            "slug":"",
            "metaDescription":"",
            "headerImage":"",
            "summary":""
        }
      }
    },
    currentSportData: {
      handler: function (newVal, oldVal) {
        if (! oldVal.id || oldVal.id !== newVal.id) {
          return // freshly loaded data
        }
        for (let k in newVal) {
          // console.log(newVal[k] + ' | ' + oldVal[k])
          if (newVal[k] !== oldVal[k]) {
            // console.log(k + ' changed')
            // updateSport (key, val)
          }
        }
      },
      deep: true
    }
//     'currentSportData.label' () {
//       this.setSlug()
//     }
  },
  created () {
    this.$store.dispatch('getSports').then(() => {
      this.sportsData = this.$store.getters.sports
      this.setOptions()
    })
  },
  destroyed() {
    this.$store.dispatch('resetSports')
  }
}
</script>